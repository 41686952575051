<template>
  <div class="w-full">
    <div
      class="mb-9 mt-[8px] lg:mt-[12px] w-full flex justify-between items-center cursor-pointer"
    >
      <!-- <PhotosHeader :hide-qr="true" class="ml-auto !mt-0" /> -->
    </div>
    <!-- <VuePictureSwipe
      ref="pictureSwipe"
      :items="type === 'SCS' ? formatPhoto(photos) : formatPhoto(photosGC)"
      data-test-id="gensapp-photodrop-everyone-openGallery"
    /> -->
    <div id="pictureGallery" class="flex gap-2 flex-wrap hidden">
      <a
        v-for="(photo, key) in formatPhoto(photos)"
        :key="key"
        :href="photo.type === 'video' ? photo.videoUrl : photo.url"
        :data-pswp-width="photo.w"
        :data-pswp-height="photo.h"
        :data-pswp-type="photo.type"
        target="_blank"
        rel="noreferrer"
      >
        <img
          v-if="photo.type === 'video'"
          :src="photo.videoThumbnailUrl"
          class="w-full"
        />
      </a>
    </div>
      <VueFlexWaterfall
        align-content="center"
        col="4"
        col-spacing="8"
        :break-at="{ 1024: 2, 768: 2, 375: 2 }"
      >
        <div
          v-for="(photo, index) in photos"
          :key="index"
          class="relative cursor-pointer w-[129px] xs:w-[155px] md:w-[235px] lg:w-[235px]"
        >
          <img
            v-if="isSelectingPhotos && isPhotoSelected(photo)"
            class="absolute z-[1] block bottom-6 right-2 w-[20px] md:w-[28px] h-[20px] md:h-[28px] border border-[#00A857] rounded-full cursor-pointer"
            src="/assets/icons/blue-tick.svg"
            alt=""
            @click="selectPhotos(photo, index)"
          />

          <span
            v-if="isSelectingPhotos && !isPhotoSelected(photo)"
            class="absolute z-[1] bottom-6 right-2 block w-[20px] md:w-[28px] h-[20px] md:h-[28px] bg-white border border-lightblue rounded-full cursor-pointer"
            @click="selectPhotos(photo, index)"
          />
          <img
            v-if="photo.type === 'image'"
            :src="photo.previewUrl"
            class="w-[129px] xs:w-[155px] md:w-[235px] lg:w-[235px] mb-2"
            @click="selectPhotos(photo, index)"
          />
          <div
            v-else
            id="home-page-kick-off-video-container"
            class="flex flex-col mb-2 relative"
          >
            <div class="w-[129px] xs:w-[155px] md:w-[235px] lg:w-[235px]">
              <!-- <div class="absolute w-[110px] md:w-[190px] lg:w-[270px]">
              <video-player
                :ref="photo.name"
                class="h-auto aspect-video"
                :options="videoOptions"
              />
            </div> -->
              <div
                :ref="photo.name + '-preview'"
                class="relative absolute w-[129px] xs:w-[155px] md:w-[235px] lg:w-[235px]"
              >
                <img
                  :src="photo.videoThumbnailUrl"
                  class="w-[129px] xs:w-[155px] md:w-[235px] lg:w-[235px]"
                  @click="selectPhotos(photo, index)"
                />
                <div
                  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-2xl"
                >
                  <img
                    src="../../../public/assets/icons/youtube-player/play-button-dark.svg"
                    data-test-id="gensapp-home-kickoff-play-video"
                    class="w-10 h-10 lg:w-[48px] lg:h-[48px]"
                    @click="selectPhotos(photo, index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueFlexWaterfall>
      <div class="flex flex-col items-center">
        <BaseButton
          v-if="viewMore"
          class="w-[180px] mt-[12px]"
          data-test-id="gensapp-photodrop-everyone-pixieset"
          solid-classes="bg-lightblue text-white border-none !leading-[24px] !rounded-[48px] hover:bg-[#40BFEB] flex justify-center"
          @click="loadMore"
        >
          <div>View More</div>
        </BaseButton>
      </div>
  </div>
</template>

<script>
import PhotoSwipeLightbox from "../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm";
import PhotoSwipeVideoPlugin from "../../utils/photoswipe-video-plugin.esm.js";
// import VuePictureSwipe from "vue3-picture-swipe";
// TODO: Add back BaseButton when pixieset has photos
import BaseButton from "../../components/common/BaseButton.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";
import { StatusBar, Style } from "@capacitor/status-bar";
import Tooltip from "../icons/Tooltip.vue";
import VideoPlayer from "@/components/common/VideoPlayer.vue";
import { VueFlexWaterfall } from "vue-flex-waterfall";
import PhotoSwipe from "../../../node_modules/photoswipe/dist/photoswipe.esm.js";
import PhotosHeader from "@/components/Photo/PhotosHeader";
import "../../../node_modules/photoswipe/dist/photoswipe.css";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "PhotosEveryone",
  components: {
    BaseButton,
    Tooltip,
    VueFlexWaterfall,
    VideoPlayer,
    PhotosHeader,
  },
  data() {
    return {
      showingTooltip: false,
      tooltipHover: false,
      subheaderText: "",
      isModalTwoVisible: false,
      isEnter: false,
      items: this.images,
      lightbox: null,
      options: {
        index: 0,
        pinchToClose: false,
        closeOnScroll: false,
        closeOnVerticalDrag: false,
        preload: [1, 2],
        closeEl: true,
        captionEl: false,
        fullscreenEl: false,
        zoomEl: true,
        shareEl: false,
        counterEl: true,
        arrowEl: true,
        preloaderEl: true,
        spacing: 0.6,
        allowPanToNext: true,
        clickToCloseNonZoomable: false,
        arrowKeys: false,
        getDoubleTapZoom: (isMouseClick, item) => {
          if (isMouseClick) {
            return 1.5;
          } else {
            return item.initialZoomLevel < 0.7 ? 1 : 1.5;
          }
        },

        gallery: "#pictureGallery",
        children: "a",
        pswpModule: PhotoSwipe,
      },
      photoStates: {
        SCS: true,
        GC: true,
      },
      videoOptions: {
        autoplay: false,
        controls: true,
        playsinline: true,
        sources: [
          {
            src: "https://cdn.generationsmvmt.com/videos/conference-app/strong_church_summit_pre-watch__mindset_shifts_720p.mp4",
            // type: 'application/x-mpegURL',
            type: "video/mp4",
          },
        ],
      },
      photos: [],
      isLoading: false,
      isLoadingMore: false,
    };
  },
  computed: {
    ...mapState("profile", ["type"]),
    ...mapState("photos", {
      photosPreload: "everyonePhotos",
      viewMore: "viewMore",
      isSelectingPhotos: "isSelectingPhotos",
      selectedDayTab: "selectedDayTab",
      selectedPhotos: "selectedPhotos",
    }),
    text() {
      if (this.type === "SCS")
        return "Check out photos from Strong Church Summit and GenerationS Conference!";
      else return "Check out photos from the GenerationS Conference!";
    },
    noPhotosText() {
      if (this.type === "SCS")
        return "Stay tuned for the full gallery of photos and videos from Strong Church Summit & GenerationS Conference! Check back here on Thursday! 😄👌🏼";
      else
        return "Stay tuned for the full gallery of photos and videos from GenerationS Conference! Check back here on Saturday! 😄👌🏼";
    },
    photosUrl() {
      if (this.type === "SCS")
        return "https://hogcphotographyministry.pixieset.com/generationsconferenceandstrongchurchsummit/";
      else
        return "https://hogcphotographyministry.pixieset.com/generationsconference/";
    },
    isAllPhotosSelected() {
      let isSelected = true;
      this.photos.forEach((photo) => {
        isSelected = isSelected && this.selectedPhotos.includes(photo.url);
      });

      return isSelected;
    },
  },

  watch: {
    showingTooltip(value) {
      if (value) {
        window.addEventListener("mousedown", this.outsideClick);
      } else {
        window.removeEventListener("mousedown", this.outsideClick);
      }
    },
    photosPreload: {
      handler() {
        if (this.photosPreload.length === 0) {
          this.photos = this.photosPreload;
          return;
        }

        this.isLoading = true;
        const lastPhotos = this.photosPreload.slice(-20);

        const loadImages = lastPhotos.map((photo) => {
          return new Promise((resolve, reject) => {
            if (photo.type === "image") {
            const img = new Image();
            img.src = photo.previewUrl;
            img.onload = resolve;
            img.onerror = reject;
            }
            else {
              const videoThumbnail = new Image();
              videoThumbnail.src = photo.videoThumbnailUrl;
              videoThumbnail.onload = resolve;
              videoThumbnail.onerror = reject;
            }
          });
        });

        Promise.all(loadImages).then(() => {
          this.photos = this.photosPreload;
          this.isLoading = false;
        })
        .catch(() => {
          this.photos = this.photosPreload;
          this.isLoading = false;
        });
      },
      immediate: true,
    },
  },
  mounted() {
    const lightbox = new PhotoSwipeLightbox(this.options);
    const videoPlugin = new PhotoSwipeVideoPlugin(lightbox, {
      autoplay: false,
    });
    lightbox.on("uiRegister", function () {
      const logEvent= function(pathName) {
        const pos = pathName.lastIndexOf('/')
        const fileName = pathName.substring(pos + 1)
        try{
          FirebaseAnalytics.logEvent({
            name: "click_button",
            params: {
              "button_name": "gensapp-photodrop-photos-everyone-download",
              "page": "photoDrop",
              "fileName": fileName
            }
          })
        } catch(e){}
        try{
          window.dataLayer.push({
            event: 'gensapp-photodrop-photos-everyone-download-web',
            file_name: fileName
          })
        } catch(e){}
      }
      lightbox.pswp.ui.registerElement({
        name: "download-button",
        order: 8,
        isButton: true,
        tagName: "a",

        // SVG with outline
        html: '<svg class="pswp__download__btn" width="32" height="32" viewBox="0 0 32 32" fill="none"><g clip-path="url(#clip0_10929_39313)"><rect width="32" height="32" rx="16" fill="#FFD900"/><g clip-path="url(#clip1_10929_39313)"><path d="M8.99976 20.375V22.125C8.99976 22.5891 9.18413 23.0342 9.51232 23.3624C9.84051 23.6906 10.2856 23.875 10.7498 23.875H21.2498C21.7139 23.875 22.159 23.6906 22.4872 23.3624C22.8154 23.0342 22.9998 22.5891 22.9998 22.125V20.375" stroke="#373E49" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.6248 15.125L15.9998 19.5L20.3748 15.125" stroke="#373E49" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 9V19.5" stroke="#373E49" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/></g></g><defs><clipPath id="clip0_10929_39313"><rect width="32" height="32" rx="16" fill="white"/></clipPath><clipPath id="clip1_10929_39313"><rect x="5.5" y="5.5" width="21" height="21" fill="white"/></clipPath></defs></svg>',

        onInit: (el, pswp) => {
          el.setAttribute("download", "");
          el.setAttribute("target", "_blank");
          el.setAttribute("rel", "noopener");
          el.setAttribute("data-event-tracking", "gensapp-photodrop-photos-everyone-download-web")
          pswp.on("change", () => {
             // console.log('change');
             el.removeEventListener('click', logEvent(el.href));
             el.href = pswp.currSlide.data.src; 
             el.addEventListener('click', logEvent(el.href));
          })
        },
      });
    });
    lightbox.init();
    lightbox.on("openingAnimationStart", async () => {
      if (isIosNativeOnly()) {
        const pswp = document.getElementsByClassName("pswp__top-bar");
        pswp[0].style.height = "104px";
        pswp[0].style.paddingTop = "44px";
        await StatusBar.setStyle({ style: Style.Dark });
      }
    });
    lightbox.on("closingAnimationStart", async () => {
      if (isIosNativeOnly()) {
        await StatusBar.setStyle({ style: Style.Light });
      }
    });
    this.lightbox = lightbox;
  },
  methods: {
    isPhotoSelected(photo) {
      return this.selectedPhotos.includes(photo.url);
    },
    outsideClick(event) {
      const { tooltip, tooltipTrigger } = this.$refs;

      if (!tooltip.contains(event.target) && event.target !== tooltipTrigger) {
        this.showingTooltip = false;
      }
    },
    toggleTooltip() {
      this.showingTooltip = !this.showingTooltip;
    },
    formatPhoto(photos) {
      const formatted = photos;
      for (const key in formatted) {
        formatted[key].w = 600;
        formatted[key].h = 600;
      }
      return formatted;
    },
    // play(ref) {
    //   this.$refs[ref][0].player.play();
    //   this.$refs[ref + "-preview"][0].classList.add("z-[-100]");
    // },
    async loadMore() {
      if (this.isLoadingMore) return;
      this.isLoadingMore = true;

      const url = new URL(this.photos[this.photos.length - 1].url);
      await this.$store.dispatch("photos/fetchPhotosEveryone", {
        tabName: this.selectedDayTab,
        startWith: url.pathname.substring(1, url.pathname.length),
        // startWith: this.photos[this.photos.length - 1].url,
      });
      // Deprecated due to cdn optimization and bug because of not complying with api request
      // await new Promise((resolve) => setTimeout(resolve, 1500));
      // await this.$store.dispatch("photos/fetchPhotosEveryone", {
      //   tabName: this.selectedDayTab,
      //   startWith: url.pathname.substring(1, url.pathname.length),
      // });

      this.isLoadingMore = false;
    },
    async selectPhotos(photo, index) {
      if (this.isSelectingPhotos) {
        await this.$store.dispatch("photos/toggleSelectedPhoto", photo.url);
      } else {
        this.lightbox.loadAndOpen(index, {
          gallery: document.querySelector("#pictureGallery"),
        });
      }
    },
    selectAllPhotos() {
      const previousState = this.isAllPhotosSelected;
      this.photos.forEach((p, i) => {
        if (!previousState) {
          this.$store.dispatch("photos/addSelectedPhoto", p.url);
        } else {
          this.selectPhotos(p, i);
        }
      });
    },
  },
};
</script>
<style>
.tooltip-box {
  top: calc(100% + 14px);
  right: -10px;

  box-shadow: 0px 0px 5px rgba(11, 12, 15, 0.35);
  /* filter: drop-shadow(0px 0px 5px rgba(11, 12, 15, 0.35)); */
}

.tooltip-box:before {
  content: "";
  position: absolute;
  top: -7px;
  right: 10px;
  border: 6px solid #f8f9f9;
  border-color: transparent #f8f9f9 #f8f9f9 transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
  /* z-index: -1; */
  box-shadow: -3px -3px 3px rgba(11, 12, 15, 0.15);
}
.tooltip-box:after {
  content: "";
  position: absolute;
  top: -7px;
  right: 10px;
  border: 6px solid white;
  border-color: #f8f9f9 transparent transparent #f8f9f9;
  transform-origin: 0 0;
  transform: rotate(45deg);
  /* z-index: 2; */
}

.tooltip-container {
  margin: auto;
  width: 220px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.aspect-video {
  aspect-ratio: 270/152;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.day-tabs::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 375px) {
  .tooltip-container {
    margin: auto;
    width: 330px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .tooltip-container {
    margin: auto;
    width: auto;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
</style>
