<template>
  <master-layout v-slot="slotProps">
    <!-- <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
      <ion-refresher-content refreshing-spinner="lines"></ion-refresher-content>
    </ion-refresher> -->
    <div class="relative w-screen h-screen flex justify-center">
      <PhotosModal :show="showModal" @close-modal="closeModal"></PhotosModal>
      <div v-if="loading"></div>
      <div v-else class="w-full">
        <!-- when side nav is shown: width = screen width - side nav-->
        <div
          class="photodrop-sticky-header fixed w-full lg:w-[calc(100vw-292px)] px-6 pt-4 bg-grey-10 z-[4]"
          :class="{ 'pt-[60px]': isIos() }"
        >
          <div
            class="header-widgets flex flex-row mx-auto justify-between items-center"
            :class="
              tab.EVERYONE.displayText === selectedTab.displayText
                ? 'max-w-[964px]'
                : 'max-w-[616px]'
            "
          >
            <img
              src="/assets/icons/go-back-chevron.svg"
              class="cursor-pointer"
              alt="Back icon"
              @click="back"
              data-test-id="gensapp-photodrop-back"
            />
            
            <!-- My QR, Select buttons -->
            <PhotosHeader @open-modal="openModal" />
          </div>

          <div
            class="header-tabs md:w-[436px] md:mx-auto flex space-x-2 mt-5 mx-4"
          >
            <div
              v-for="(tab, index) in tabs"
              :key="`tickets-tab-${tab}-${index}`"
              class="flex justify-center items-center cursor-pointer w-full whitespace-nowrap text-[16px] xs:text-[20px] font-aktiv-grotesk text-center px-2 py-2 xs:px-4 rounded-t-[12px] md:whitespace-nowrap md:px-[42px] md:py-2 tab-card"
              :class="{
                'font-bold text-lightblue bg-white':
                  tab.displayText === selectedTab.displayText,
                'bg-[#F8F9FB]': tab.displayText !== selectedTab.displayText,
                hidden: tab.displayText === 'For everyone' && isAbsentAlumni,
              }"
              :data-event-tracking="getDataEventTracking(tab)"
              :data-test-id="'gensapp-photodrop-tab-' + tab.displayText"
              @click="onSelectTab(tab)"
            >
              {{ tab.displayText }}
            </div>
          </div>
        </div>

        <div
          class="flex flex-col pt-[124px] md:pt-[130px] w-full"
          :class="{ '!pt-[168px]': isIos() }"
        >
          <PhotoEveryoneDayTab
            v-if="tab.EVERYONE.displayText === selectedTab.displayText"
            class="fixed z-[4] top-[124px] md:top-[218px] left-0 lg:left-[292px]"
            :class="{ '!top-[168px]': isIos() }"
            ref="photoEveryOneDayTab"
          />
          <PhotoMyPhotoTypeTab
            v-if="tab.MY.displayText === selectedTab.displayText"
            class="fixed z-[4] top-[124px] md:top-[218px] left-0 lg:left-[292px]"
            :class="{ '!top-[168px]': isIos() }"
            ref="PhotoMyPhotoTypeTab"
            @refresh-photo="refreshMyPhoto"
          />
          <div
            class="flex flex-col bg-white pb-12 md:pb-[32px]"
            :class="
              tab.EVERYONE.displayText === selectedTab.displayText
                ? 'mt-[92px]'
                : 'mt-[88px]'
            "
          >
            <div
              v-if="tab.MY.displayText === selectedTab.displayText"
              class="flex flex-col px-4 xs:px-6 pb-6 lg:p-0 md:mb-8"
            >
              <div
                class="shadow-ambient flex flex-col max-w-[616px] w-full mx-auto md:justify-center"
              >
                <div v-if="isPhotosEmpty">
                  <PhotosExpPhotosEmpty :tabType="selectedMyPhotoTypeTab"/>
                </div>
                <div v-else>
                  <div style="display: flex; align-items: center;">
                    <!-- Download button -->
                    <BaseButton
                      v-if="isSelectingPhotos"
                      :solid-classes="`download-button rounded-[100px] items-center h-9 ${
                        selectedPhotos.length > 0 ? 'bg-lightblue text-grey-5' : 'bg-grey-5 text-lightblue'
                      }`"
                      :data-test-id="gensapp - photodrop - download"
                      @click="openDownloadModal(slotProps)"
                      style="margin-top: 4px;"
                    >
                      {{ isDownloading ? "Downloading" : "Download" }}
                    </BaseButton> 
                    
                    <p
                      v-if="isSelectingPhotos"
                      class="text-body-2 text-grey-80 text-center"
                      style="display: inline-block; margin-left: auto; text-align: right;"
                    >
                      {{ selectedPhotos.length }}
                      {{
                        selectedPhotos.length === 1 ? "photo" : "photos"
                      }}
                      selected
                    </p>
                  </div>
                  
                  <Gallery
                    v-for="(day, index) in photos"
                    :key="index"
                    :has-tooltip="index === 0"
                    v-bind="{ viewportWidth, ...day }"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="tab.EVERYONE.displayText === selectedTab.displayText"
              class="flex flex-col mx-6 pb-6 lg:p-0 md:mb-8"
            >
              <div class="max-w-[318px] w-full md:max-w-[478px] lg:max-w-[964px] mx-auto" style="display: flex; align-items: center;">
                  <!-- Download button -->
                  <BaseButton
                    v-if="isSelectingPhotos"
                    :solid-classes="`download-button rounded-[100px] items-center h-9 ${
                      selectedPhotos.length > 0 ? 'bg-lightblue text-grey-5' : 'bg-grey-5 text-lightblue'
                    }`"
                    :data-test-id="gensapp - photodrop - download"
                    @click="openDownloadModal(slotProps)"
                    style="margin-top: 4px;"
                  >
                    {{ isDownloading ? "Downloading" : "Download" }}
                  </BaseButton> 
                  
                  <p
                    v-if="isSelectingPhotos"
                    class="text-body-2 text-grey-80 text-center"
                    style="display: inline-block; margin-left: auto; text-align: right;"
                  >
                    {{ selectedPhotos.length }}
                    {{
                      selectedPhotos.length === 1 ? "photo" : "photos"
                    }}
                    selected
                  </p>
                </div>
                
              <div
                class="shadow-ambient flex flex-col md:flex-row max-w-[318px] w-full md:max-w-[478px] lg:max-w-[964px] mx-auto md:justify-center"
              >   
                <PhotosEveryone />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { Directory, Filesystem } from "@capacitor/filesystem";
import write_blob from "capacitor-blob-writer";
import { FileOpener } from "@capacitor-community/file-opener";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import { IonRefresher, IonRefresherContent, isPlatform } from "@ionic/vue";
import { isIosNativeOnly, isNativeNotWeb } from "@/utils/platform-check";
import { forEveryoneDaySlugs } from "@/utils/photodropPageSlugs";
import BaseButton from "../components/common/BaseButton.vue";
import PhotosHeader from "@/components/Photo/PhotosHeader";
import Gallery from "@/components/Photo/Gallery";
import PhotosQrCode from "@/components/Photo/PhotosQrCode";
import PhotosEveryone from "@/components/Photo/PhotosEveryone";
import PhotosExpPhotosEmpty from "@/components/Photo/PhotosExpPhotosEmpty";
import PhotosModal from "@/components/Photo/PhotosModal";
import PhotoEveryoneDayTab from "@/components/Photo/PhotoEveryoneDayTab";
import PhotoMyPhotoTypeTab from "@/components/Photo/PhotoMyPhotoTypeTab";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

const TAB = {
  MY: {
    displayText: "My Photos",
  },
  EVERYONE: {
    displayText: "For everyone",
  },
};

const TABS = Object.values(TAB);

export default {
  components: {
    BaseButton,
    PhotosExpPhotosEmpty,
    PhotosModal,
    PhotosEveryone,
    Gallery,
    PhotosHeader,
    PhotosQrCode,
    PhotoEveryoneDayTab,
    PhotoMyPhotoTypeTab,
    IonRefresher,
    IonRefresherContent,
  },
  layout: "photos",
  data() {
    return {
      viewportWidth: null,
      selectedTab: TAB.MY,
      tabs: TABS,
      tab: TAB,
      showModal: false,
      isDownloading: false,
      forEveryoneDaySlugs: forEveryoneDaySlugs,
      forEveryoneDaySlugMappings: {},
    };
  },
  computed: {
    ...mapState("photos", [
      "photos",
      "isSelectingPhotos",
      "selectedPhotos",
      "isShowQr",
      "loading",
      "selectedDayTab",
      "selectedMyPhotoTypeTab",
    ]),
    ...mapGetters("photos", ["isPhotosEmpty"]),
    ...mapGetters("schedule", ["scheduleDates"]),
    ...mapGetters("profile", ["isAbsentAlumni"]),
    ...mapState("user", ["token"]),
    isWhichDayAnchorTagPresentInTheUrl() {
      if (this.$route.hash) {
        return this.forEveryoneDaySlugs.includes(this.$route.hash.slice(1));
      }
      return false;
    },
  },
  async ionViewWillEnter() {
    this.populateForEveryoneDaySlugMappings();
    await this.$store.dispatch(
      "photos/fetchPhotos",
      this.selectedMyPhotoTypeTab
    );
  },
  async ionViewDidEnter() {
    await this.$store.dispatch("announcements/refreshAnnouncements");
    await this.$store.dispatch("announcements/fetchUnreadCount");
    if (this.isWhichDayAnchorTagPresentInTheUrl) {
      // Switch to the For Everyone tab
      await this.onSelectTab(this.tabs[1]);
      await this.$refs.photoEveryOneDayTab.changeDayTab(
        this.getDateFromAnchorTag()
      );
    }
    FirebaseAnalytics.setScreenName({
      screenName: "photodrop - my photos",
      nameOverride: "PhotoDropPage - My Photos",
    });
    await this.$store.dispatch("signup/fetchExpData");
  },
  mounted() {
    this.viewportWidth = window.innerWidth;
    window.addEventListener("resize", this.checkViewportWidth);

    this.showModal = this.isShowQr;
  },
  destroyed() {
    window.removeEventListener("resize", this.checkViewportWidth);
  },
  methods: {
    getDataEventTracking(tab) {
      if (tab.displayText === TAB.MY.displayText) {
        return "photodrop-my-photo";
      } else if (tab.displayText === TAB.EVERYONE.displayText) {
        return "photodrop-for-everyone";
      }
    },
    isIos() {
      return isIosNativeOnly();
    },
    checkViewportWidth() {
      this.viewportWidth = window.innerWidth;
    },
    back() {
      this.$router.push("/home");
    },
    resetSelectPhotos() {
      this.$store.dispatch("photos/updateIsSelectingPhotos", false);
      this.$store.dispatch("photos/resetSelectedPhotos");
    },
    async onSelectTab(tab) {
      this.selectedTab = tab;
      this.resetSelectPhotos();
      if (this.selectedTab.displayText === "For everyone") {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          params: {
            button_name: "photodrop-for-everyone",
            page: "photodrop",
          },
        });
        await this.$store.dispatch("photos/fetchPhotosEveryone", {
          tabName: this.selectedDayTab,
        });
        FirebaseAnalytics.setScreenName({
          screenName: "photodrop - for everyone",
          nameOverride: "PhotoDropPage - For Everyone",
        });
      } else if (this.selectedTab.displayText === "My Photos") {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          params: {
            button_name: "photodrop-my-photo",
            page: "photodrop",
          },
        });
        FirebaseAnalytics.setScreenName({
          screenName: "photodrop - my photos",
          nameOverride: "PhotoDropPage - My Photos",
        });
      }
    },
    async loadPhotos() {
      if (this.selectedTab.displayText === "For everyone") {
        await this.$store.dispatch("photos/fetchPhotosEveryone", {
          tabName: this.selectedDayTab,
        });
      } else {
        await this.$store.dispatch(
          "photos/fetchPhotos",
          this.selectedMyPhotoTypeTab
        );
      }
    },
    async refreshMyPhoto() {
      this.resetSelectPhotos();
      this.loadPhotos();
    },
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      this.showModal = true;
    },
    openDownloadModal(slotProps) {
      slotProps.togglePhoto();
      if (this.selectedTab.displayText === "For everyone") {
        for (const key in this.selectedPhotos) {
          const urlLink = new URL(this.selectedPhotos[key]);
          const result = urlLink.pathname;
          console.log(result);
          try {
            FirebaseAnalytics.logEvent({
              name: "file_downloaded_for_everyone",
              params: {
                file_name: result,
              },
            });
          } catch (e) {}
          try {
            window.dataLayer.push({
              event: "file-downloaded-web-for-everyone",
              file_name: result,
            });
          } catch (e) {}
        }
        try {
          FirebaseAnalytics.logEvent({
            name: "click_button",
            params: {
              button_name: "photodrop-download-button-for-everyone",
              page: "photodrop",
              file_quantity: this.selectedPhotos.length,
            },
          });
        } catch (e) {}
        try {
          window.dataLayer.push({
            event: "photodrop-download-web-for-everyone",
            file_quantity: this.selectedPhotos.length,
            page_section: "for-everyone",
          });
        } catch (e) {}
      } else if (this.selectedTab.displayText === "My Photos") {
        for (const key in this.selectedPhotos) {
          const urlLink = new URL(this.selectedPhotos[key]);
          const result = urlLink.pathname;
          console.log(result);
          try {
            FirebaseAnalytics.logEvent({
              name: "file_downloaded_my_photos",
              params: {
                file_name: result,
              },
            });
          } catch (e) {}
          try {
            window.dataLayer.push({
              event: "file-downloaded-web-my-photos",
              file_name: result,
            });
          } catch (e) {}
        }
        try {
          FirebaseAnalytics.logEvent({
            name: "click_button",
            params: {
              button_name: "photodrop-download-button-my-photo",
              page: "photodrop",
              file_quantity: this.selectedPhotos.length,
            },
          });
        } catch (e) {}
        try {
          window.dataLayer.push({
            event: "photodrop-download-web-my-photos",
            file_quantity: this.selectedPhotos.length,
            page_section: "my-photos",
          });
        } catch (e) {}
      }
    },
    async download() {
      // if (this.selectedTab.displayText === "For everyone"){

      //   for (const key in this.selectedPhotos){
      //     const urlLink = new URL(this.selectedPhotos[key])
      //     const result = urlLink.pathname
      //     console.log(result)
      //     try {
      //         FirebaseAnalytics.logEvent({
      //         name: "file_downloaded_for_everyone",
      //         params: {
      //           "file_name": result
      //         }
      //       })
      //     } catch (e) {

      //     }
      //     try {
      //       window.dataLayer.push({
      //         event: 'file-downloaded-web-for-everyone',
      //         file_name: result
      //       })
      //     } catch (e) {

      //     }
      //   }
      //   try{
      //     FirebaseAnalytics.logEvent({
      //       name: "click_button",
      //       params: {
      //         "button_name": "photodrop-download-button-for-everyone",
      //         "page": "photodrop",
      //         "file_quantity": this.selectedPhotos.length,
      //       }
      //     })
      //   } catch(e){}
      //   try{
      //     window.dataLayer.push({
      //       event: 'photodrop-download-web-for-everyone',
      //       file_quantity: this.selectedPhotos.length,
      //       page_section: "for-everyone"
      //     });
      //   } catch(e){}
      // }
      // else if (this.selectedTab.displayText === "My Photos") {

      //   for (const key in this.selectedPhotos){

      //     const urlLink = new URL(this.selectedPhotos[key])
      //     const result = urlLink.pathname
      //     console.log(result)
      //     try{
      //         FirebaseAnalytics.logEvent({
      //         name: "file_downloaded_my_photos",
      //         params: {
      //           "file_name": result
      //         }
      //       })
      //     } catch(e){}
      //     try{
      //         window.dataLayer.push({
      //         event: 'file-downloaded-web-my-photos',
      //         file_name: result
      //       });
      //     } catch(e){}
      //   }
      //   try {
      //       FirebaseAnalytics.logEvent({
      //         name: "click_button",
      //         params: {
      //           "button_name": "photodrop-download-button-my-photo",
      //           "page": "photodrop",
      //           "file_quantity": this.selectedPhotos.length
      //         }
      //       })
      //   } catch(e){}
      //   try {
      //     window.dataLayer.push({
      //       event: 'photodrop-download-web-my-photos',
      //       file_quantity: this.selectedPhotos.length,
      //       page_section: "my-photos"
      //     });
      //   } catch(e){}
      // }

      if (this.isDownloading) return;

      // TODO: refactor the axio
      if (!this.selectedPhotos.length) return;
      this.isDownloading = true;

      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL + "/photo/multi-download",
        {
          method: "post",
          data: { presignedUrls: this.selectedPhotos },
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );

      // Start downloading the zip file
      if (isNativeNotWeb()) {
        this.nativeFileDownload(data);
      } else {
        this.webDownload(data);
      }
    },
    async handleRefresh(event) {
      setTimeout(async () => {
        // Any calls to load data go here
        await this.loadPhotos();
        event.target.complete();
      }, 1000);
    },
    async nativeFileDownload(downloadUrl) {
      const nativeFilePath = "download/photo.zip";

      const data = await fetch(downloadUrl).then((result) => result.blob());

      write_blob({
        path: nativeFilePath,
        directory: Directory.Data,
        blob: data,
        fast_mode: true,
        recursive: true,
        on_fallback(error) {
          console.error(error);
        },
      })
        .then(async function () {
          console.log("Video written.");
          console.log(nativeFilePath);
          const nativeFullFilePath = await Filesystem.getUri({
            path: nativeFilePath,
            directory: Directory.Data,
          });
          console.log(nativeFullFilePath);
          await FileOpener.open({
            filePath: nativeFullFilePath.uri,
            contentType: isPlatform("ios") ? "application/zip" : "text/plain",
          });
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    webDownload(downloadUrl) {
      // Start downloading the zip file
      // const downloadUrl = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.target = "_blank";
      link.download = "photos.zip";
      link.click();
      this.isDownloading = false;
    },
    populateForEveryoneDaySlugMappings() {
      this.forEveryoneDaySlugs.forEach((slug, index) => {
        if (this.scheduleDates[index]) {
          this.forEveryoneDaySlugMappings[slug] = this.scheduleDates[index];
        } else {
          this.forEveryoneDaySlugMappings[slug] = "Featured";
        }
      });
    },
    getDateFromAnchorTag() {
      const hash = this.$route.hash.slice(1);
      return this.forEveryoneDaySlugMappings[hash];
    },
  },
};
</script>

<style>
.qr-container {
  /* background-image: linear-gradient(to bottom right, #FFD900 12%,#FFD900, #FFD900, #FFD900); */
  background-color: #ffd900;
}

.tab-card {
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.08);
}

ion-refresher.refresher-native {
  display: block;
  z-index: 100;
}
</style>
